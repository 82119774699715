
<template>
    <layout>
      <b-card>
        <b-col>
            <b-row>
                <b-col style="display:flex;">
                    <div>
                        <i class="ri-arrow-left-s-line" style="font-size:30px;" @click="GotToBack()"></i>
                    </div>
                    <div>
                        <h4 style="padding-top:10px;padding-left:20px;">Facture</h4>
                    </div>
                   
                </b-col>
            </b-row>
          <b-row class="mt-4">
            <b-col style="display:flex;">
              <h6>Groupe</h6>
            </b-col>
            <b-col>
              <h6>{{Invoice.classtitle}} | {{Invoice.classsubject}}  </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="display:flex;">
              <h6>Statut</h6>
            </b-col>
            <b-col>
                <div :style="'background-color:'+'darkred'+';padding-top:5px;padding-left:25px;padding-bottom:5px;border-radius:10px;max-width:150px;'"> <h6 style="color:white;">Non payé</h6></div>
             
            </b-col>
          </b-row>
          <b-row>
            <b-col><hr></b-col>
          </b-row>
          <b-row>
            <b-col>
                <b-row>
                    <b-col>
                      <h5>  Paiement à</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                      <h6>  Lilas Langues</h6>
                    </b-col>
                </b-row>
              <!--  <b-row>
                    <b-col>
                      <h6> 25 El Islah cooperative city july 5th N°08, Bordj Bou Arreridj.</h6>
                    </b-col>
                </b-row>-->
                <b-row v-if="school!=null">
                    <b-col>
                      <h6> Télé: {{school.tele}}<br> Mob: {{school.phone}}</h6>
                    </b-col>
                </b-row>
                <b-row v-if="school!=null">
                    <b-col>
                      <h6> {{school.email}}</h6>
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col>
                      <h5> Facturer à</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                      <h6> {{Invoice.student.username}}</h6>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                      <h6>{{Invoice.student.address}}</h6>
                    </b-col>
                </b-row>
               
            </b-col>
          </b-row>
          <b-row>
            <b-col><hr></b-col>
          </b-row>
          <b-row>
            <b-col>
                <h5>Sous-total : </h5>
            </b-col>
  
            <b-col>
              <h5><b>{{Invoice.price}} DZD</b></h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
                <h5>Paiement effectué : </h5>
            </b-col>
  
            <b-col>
              <h5><b>{{Invoice.totalpayment}} DZD</b></h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
                <h5>Reste à payer : </h5>
            </b-col>
  
            <b-col>
              <h5><b> {{Invoice.price - Invoice.totalpayment}} DZD</b></h5>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
    </layout>
  </template>
  <script>
  import Layout from "../../layouts/main";
  import {schoolsMethods
} from "@/state/helpers";
  export default {
    components: {
      Layout,
    },
    data() {
      return {
        Invoice:null
      };
    },
    created(){      
    this.Invoice = JSON.parse(localStorage.getItem("invoice"));
    
    let Student = JSON.parse(localStorage.getItem("user"));
    this.getSchools({ id:Student.schoolId})
    },
    computed:{
      school() {
      return this.$store ? this.$store.state.schools.Schools[0] : null;
    },
    },
    methods:{
        GotToBack(){
            this.$router.go(-1);
        },
        ...schoolsMethods,
    }
  };
  </script>
  